import { put } from "redux-saga/effects";
import { MESSAGE } from "../constans";

export function* addMessage({ payload: { values, firestore, firebase } }) {
  try {
    let obj = {
      ...values,
    };
    delete obj.attached;
    const { id } = yield firestore.collection("messages").add(obj);
    const link = firebase.storage().ref(`files/${id}/`);
    yield link.put(values.attached);
    const url = yield link.getDownloadURL();
    firestore
      .collection("messages")
      .doc(id)
      .update({ ...values, attached: url });
    yield put({
      type: MESSAGE,
      payload: {
        type: "",
        text: "Your request has been sent",
      },
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: MESSAGE,
      payload: {
        type: "err",
        text: error.message,
      },
    });
  }
}
