export const viewHeight = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};
export const imgView = (loaded, img) => {
  return img
    ? URL.createObjectURL(
        new Blob([img], {
          type: "application/zip",
        })
      )
    : loaded;
};
