export const CHANGE_LP_PAGE = "CHANGE_LP_PAGE";
export const MENU_STATE = "MENU_STATE";
export const SHOW_PROJECTS = "SHOW_PROJECTS";
export const MESSAGE = "MESSAGE";
export const ADD_MESSAGE = "ADD_MESSAGE";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";

export const CREATE_PROJECT = "CREATE_PROJECT";
export const DELETE_REQUEST = "DELETE_REQUEST";
export const EDIT_REQUEST = "EDIT_REQUEST";
